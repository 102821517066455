import React from 'react'
import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { getConfidenceBgColor, getConfidenceColor } from 'utils/helpers'
import { useAppSelector } from 'stateHandling/hooks'

const PlayAnswerChildren = () => {
	const { t } = useTranslation()
	const audioUrl = useAppSelector(state => state.currentExercise.recordedAudio)
	const recordedAudio = new Audio(audioUrl)

	return (
		<div className='text-end'>
			<Button className='exercise--record-button text-nowrap rounded-circle mx-5' variant='primary' onClick={() => recordedAudio.play()}>
				<FontAwesomeIcon icon={faVolumeUp} />
			</Button>
		</div>
	)
}

export default PlayAnswerChildren