import { useTranslation } from 'react-i18next'
import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'stateHandling/hooks'
import { Table, Container, Button, Alert, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { setPatientListLoading, getPatientList } from 'reducers/userSlice'
import { setCurrentPatient } from 'reducers/currentPatientSlice'
import { changePreviousPage } from 'reducers/previousPageSlice'
import Loading from 'components/Loading'
import { getPatientUserIconColor } from 'utils/helpers'
import { Dispatch } from 'types/Types'
import { AssignedExercise, AssignedPatientUser } from 'types/UserTypes'
import { Tabs, Tab } from 'react-bootstrap'
import statisticsService from 'services/statistics'

import './PatientsListPage.css'
import { Attempt,DBStatistic } from 'types/StatisticTypes'
import dayjs from 'dayjs'

interface LastStatAssignedPatientUser extends AssignedPatientUser {
	latestAttemptEndDate: string | null
}

const PatientsListPage = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()
	const dispatch = useDispatch<Dispatch>()
	const user = useAppSelector(state => state.user)
	const patients = useAppSelector(state => state.user.data.patients) as AssignedPatientUser[]
	const [childPatients, setChildPatients] = useState<LastStatAssignedPatientUser[]>([])
	const [adultPatients, setAdultPatients] = useState<LastStatAssignedPatientUser[]>([])
	const [currentPatientCategory, setCurrentPatientCategory] = useState<LastStatAssignedPatientUser[]>([])
	const [patientsWithLastStat, setPatientsWithLastStat] = useState<LastStatAssignedPatientUser[]>([])
	const [patientStatsLoading, setPatientStatsLoading] = useState<boolean>(true)
	const [index, setIndex] = useState<number>(0)


	const getLatestAttemptEndDate = (attempts: Attempt[]): string | null => {
		if (attempts.length === 0) return null

		// Sort attempts by end date in descending order and get the latest one
		const sortedAttempts = attempts.sort((a, b) => new Date(b.end).getTime() - new Date(a.end).getTime())
		return sortedAttempts[0].end
	}

	const patientsFullyLoaded = (patients) => {
		return patients.every(patient => Object.prototype.hasOwnProperty.call(patient, 'cognitoId'))
	}

	const fetchAndAppendLatestAttempts = async () => {
		const patientsWithLatestEndDates = await Promise.all(patients.map(async (patient) => {
			try {
				const statsByExercise = await statisticsService.getStatsForUser(patient.cognitoId)

				// Convert statsByExercise object to an array of DBStatistic
				const statsArray: DBStatistic[] = Object.values(statsByExercise)

				const latestEndDate = statsArray.reduce((latest, stat) => {
					const attemptEndDate = getLatestAttemptEndDate(stat.attempts)
					return attemptEndDate && (!latest || new Date(attemptEndDate).getTime() > new Date(latest).getTime()) ? attemptEndDate : latest
				}, null as string | null)

				return {
					...patient,
					latestAttemptEndDate: latestEndDate ? dayjs(latestEndDate).format('YYYY-MM-DD') : null
				}
			} catch (error) {
				console.error('Failed to fetch stats for user', error)
				return {
					...patient,
					latestAttemptEndDate: null
				}
			}
		}))

		setPatientsWithLastStat(patientsWithLatestEndDates)
	}

	useEffect(() => {
		if (patientsFullyLoaded(patients)){
			fetchAndAppendLatestAttempts()
			setPatientStatsLoading(false)
		}

	}, [patients])

	useEffect(() => {
		const childPatients = patientsWithLastStat.filter(patients => !patients.isAdult)
		const adultPatients = patientsWithLastStat.filter(patients => patients.isAdult)
		setChildPatients(childPatients)
		setAdultPatients(adultPatients)
	}, [patientsWithLastStat])

	useEffect(() => {
		index === 0 ? setCurrentPatientCategory(childPatients) : setCurrentPatientCategory(adultPatients)
	}, [index, adultPatients, childPatients])

	/**
     * UseEffect to update the previousPage
     */
	useEffect(() => {
		dispatch(changePreviousPage('/patients'))
	}, [])
	/**
     * Callback for fetching and setting the redux state for patient data
     */
	const fetchPatients = useCallback(async userId => {
		dispatch(setPatientListLoading(true))
		dispatch(getPatientList(userId))
	}, [])

	/**
     * Call the callback for fetching and storing the patients data
     */
	useEffect(() => {
		try {
			fetchPatients(user.data.id)
		} catch (exception) {
			console.error(exception)
		}
	}, [fetchPatients])

	const openUserPage = (idx: number) => {
		const patient = currentPatientCategory[idx]
		dispatch(setCurrentPatient(patient))
		const id = patient.id
		navigate(`/patients/${id}`)
	}

	const createUser = () => {
		navigate('/create_user')
	}

	const getNumDistinctExercises = (exercises: AssignedExercise[]) => {
		let numExercises = 0
		exercises.forEach(e => {
			numExercises += e.answerFormat.length
		})
		return numExercises
	}

	const handleSelectPatientCategory = (idx) => setIndex(parseInt(idx))

	return (
		<Container>
			{user.status.patientListLoading
				? <Loading />
				: <>
					<Row xs="auto">
						<Col className='flex-grow-1'>
							<h2>{t('edit_patient.list.title')}</h2>
						</Col>
					</Row>
					<hr />
					<Col className='flex-grow-1' xs={6} sm={8} data-cy='patients_list_subtitle'>
						<p>{t('edit_patient.list.subtitle')}</p>
					</Col>
					{user.status.message.showMessage
						? <Row>
							<Alert variant={user.status.message.variant}>
								{user.status.message.message}
							</Alert>
						</Row>
						: <></>
					}
					<Row xs='auto'>
						<Col className='flex-grow-1' xs={6} sm={8}>
							<Tabs
								defaultActiveKey={0}
								className='mb-3'
								onSelect={(idx) => handleSelectPatientCategory(idx)}
								data-cy='patient_list_toggle_patient_category'
							>
								<Tab eventKey='0' title={t('edit_patient.list.category_toggle_children')}/>
								<Tab eventKey='1' title={t('edit_patient.list.category_toggle_adults')}/>
							</Tabs>
						</Col>
						<Col className='float-end text-end mb-4'>
							<Button className='mb-2' onClick={createUser} data-cy='patients_list_add_patient_button'>
								<FontAwesomeIcon icon={faAdd}/>
								<span className='mx-2'>{t('edit_patient.list.add_patient_button')}</span>
							</Button>
						</Col>
					</Row>
					<Table striped hover responsive>
						<thead className='patients-list--thead' data-cy='patients_list_table'>
							<tr>
								<th>{t('edit_patient.list.table.username')}</th>
								<th>{t('edit_patient.list.table.active_exercises')}</th>
								{/* TODO: add this when loading statistics for each user in the list view..
								<th>{t('edit_patient.list.table.finished_exercises')}</th>
								*/}
								<th>{t('edit_patient.list.table.latest_active')}</th>
								<th></th>
							</tr>
						</thead>
						<tbody style={{cursor: 'pointer', borderTop: 'none'}}>
							{currentPatientCategory.map((patient, i) => {
								return (
									<tr key={i} onClick={() => openUserPage(i)}
										className='patients-list--row' data-cy='patients_list_table_row'
									>
										<td>
											{patient.username
												? <Row className='justify-content-start'>
													<Col className='mx-2' xs={4} sm={2}>
														<div className='patients-list--letter-circle'
															style={{
																backgroundColor: getPatientUserIconColor(patient.username)
															}}
														>
															{patient.username.charAt(0).toUpperCase()}
														</div>
													</Col>
													<Col className="align-self-center">{patient.username}</Col>
												</Row>
												:<></>
											}
										</td>
										<td className='m-2'>
											{getNumDistinctExercises(patient.exercises ? patient.exercises : [])}
										</td>
										{/* TODO: add this when loading statistics for each user in the list view..
											<td className='m-2'>#</td>
										*/}
										<td className='m-2'>{patient.latestAttemptEndDate}</td>
										<td ><FontAwesomeIcon icon={faChevronRight}/></td>
									</tr>
								)
							})}
						</tbody>
					</Table>
				</>}
		</Container>
	)
}

export default PatientsListPage