// https://redux.js.org/usage/configuring-your-store/

import { MiddlewareAPI, Dispatch, Middleware,Action } from 'redux'

// TODO: Not sure about best practice here, needs more research
const logger:Middleware = (store:MiddlewareAPI) => (next:Dispatch) => (action:Action)=> {
	console.group(action.type)
	console.info('dispatching', action)
	const result = next(action)
	console.log('next state', store.getState())
	console.groupEnd()
	return result
}

export default logger
