import { Button, Row, Col } from 'react-bootstrap'
import React, { useEffect } from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import Hotkeys from 'react-hot-keys'
import { EXERCISE_PROGRESS_STATUS_RETRY } from 'utils/config'

import correctAnswer from 'sounds/correct_answer_1.mp3'
import wrongAnswer from 'sounds/wrong_answer.mp3'

const StatusNext = ({ handleQuestion, handleRetry, retry=false }) => {
	const stats = useAppSelector(state => state.currentExercise.stats)

	useEffect(() => {
		playSound()
	}, [stats.status])

	const playSound = () => {
		const answerCorrect = stats.answerStatus === 'correct'
		if (answerCorrect) {
			const correctSound = getSound(true)
			if (correctSound) correctSound.play()
		} else {
			const wrongSound = getSound(false)
			if (wrongSound) wrongSound.play()
		}
	}

	const getSound = correct => {
		if (correct) return new Audio (correctAnswer)
		return new Audio (wrongAnswer)
	}

	const onKeyDown = (keyName, event) => {
		event.preventDefault()
		handleQuestion()
	}



	return (
		<Row className='exercise--footer-fixed justify-content-center'>
			<Col className='text-center flex-grow-1 align-self-center'>
				{retry && stats.status === EXERCISE_PROGRESS_STATUS_RETRY
					? <Button
						className='text-nowrap exercise--record-button rounded-circle mx-5'
						onClick={handleRetry} variant={'primary'}
						data-cy='exercise_question_retry_button'
					>
						<FontAwesomeIcon icon={faRotateRight} />
					</Button>
					: <></>}
				<Hotkeys keyName="enter" onKeyDown={onKeyDown}>
					<Button
						className='text-nowrap exercise--record-button rounded-circle mx-5'
						onClick={handleQuestion} variant={retry && stats.status === EXERCISE_PROGRESS_STATUS_RETRY ? 'outline-primary' : 'primary'}
						data-cy='exercise_question_next_button'
					>
						<FontAwesomeIcon icon={faArrowRight} />
					</Button>
				</Hotkeys>
			</Col>
		</Row>
	)
}

export default StatusNext